import {SENTRY_DSN, SENTRY_TUNNEL} from "@config";
import * as Sentry from "@sentry/browser";
import * as SentryVue from "@sentry/vue";

export function initSentry(app?: any) {
  if (SENTRY_DSN) {

    document.addEventListener('DOMContentLoaded', () => {
      const userId = document.querySelector("meta[name=uid]")?.getAttribute("content");
      if (userId) {
        Sentry.setUser({ id: userId })
        SentryVue.setUser({ id: userId })
      }
    })

    if (app) {
      initSentryVue(app)
    } else {
      initSentryBrowser()
    }
  }
}

function initSentryBrowser() {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      // Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tunnel: SENTRY_TUNNEL,
  })
}

function initSentryVue(app: any) {
  SentryVue.init({
    app: app,
    dsn: SENTRY_DSN,
    integrations: [
      // Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tunnel: SENTRY_TUNNEL,
  })
}
